const productsRestUrl = 'https://api-products.metus-cms.com/api'

export const submitFormData = async (formData, webToLead) => {
    const url = webToLead ? 'https://lead.tmehvac.com/sf/sfstaging.php' : 'https://26u95xsyc6.execute-api.us-east-1.amazonaws.com/default/sf-leads'

    const res = await fetch(url, {
        method: 'POST',
        body: formData,
    })
    if (!res.ok) {
        return console.error('Post Error', res)
    }
    const data = await res.json()
    return data
}

export const submitToSandbox = async (formData) => {
    const url = 'https://26u95xsyc6.execute-api.us-east-1.amazonaws.com/default/sandbox-leads-testing'
    const res = await fetch(url, {
        method: 'POST',
        body: formData,
    })
    if (!res.ok) {
        return console.error('Post Error', res)
    }
    const data = await res.json()
    return data
}

export const fetchLocalData = async (filename) => {
    try {
        const file = await fetch(`/data/${filename}`)
        const data = await file.json()
        return data
    } catch (error) {
        console.error(error)
        return error
    }
}

export const fetchOutdoorUnits = async (params) => {
    const restOutdoorUrl = `${productsRestUrl}/outdoor-units?pagination[pageSize]=100&populate=%2A`
    const url = params ? `${restOutdoorUrl}?${params}` : restOutdoorUrl
    try {
        const res = await fetch(url)
        const data = await res.json()
        // console.log('outdoor:', data.data)
        return data.data
    } catch (error) {
        console.error(error)
        return error
    }
}

export const fetchIndoorUnits = async (params) => {
    const restIndoorUrl = `${productsRestUrl}/indoor-units?pagination[pageSize]=100&populate=%2A`
    const url = params ? `${restIndoorUrl}?${params}` : restIndoorUrl
    try {
        const res = await fetch(url)
        const data = await res.json()
        // console.log('indoor:', data.data)
        return data.data
    } catch (error) {
        console.error(error)
        return error
    }
}

export const fetchIpData = async () => {
    const url = `https://master.d28mnh9p7akn7p.amplifyapp.com/api/geoservices/v1.0/locate`
    try {
        const res = await fetch(url)
        const data = await res.json()
        console.log('IP Data:', data)
        return data.data
    } catch (error) {
        console.error(error)
        return error
    }
}

export const fetchEcoRebates = async (zip, model) => {
    const path = 'https://www.mitsubishicomfort.com/api/eco-rebates'
    let params = `zip=${zip}`
    if (model) {
        params = params + `&model=${model}`
    }
    const url = `${path}?${params}`
    try {
        const res = await fetch(url)
        const data = await res.json()
        // console.log('Raw EcoRebates data:', data.data)
        return data.data
    } catch (error) {
        console.error(error)
        return error
    }
}
